import { useDb } from './setup'
import type {FullContract} from '~/models/contract';
import cloneDeep from 'lodash/cloneDeep';

const { dbPromise } = useDb()

const insertDrafts = async (drafts: FullContract[]): Promise<boolean> => {
  try {
    // (await dbPromise).clear('contracts')
    const tx = (await dbPromise).transaction('drafts', 'readwrite')
    drafts.forEach((draft: FullContract) => {
      tx.store.put(draft)
    })
    await tx.done
    return true
  } catch (error) {
    console.error('insert Contract error', error)
    return false
  }
}

const draftsDb = {
  async getById (id: string): Promise<FullContract> {
    try {
      return (await dbPromise).get('drafts', id)
    } catch (error) {
      console.error('draftsDb: getById', error)
    }
  },
  async getAll (): Promise<FullContract[]> {
    try {
      return (await dbPromise).getAll('drafts')
    } catch (error) {
      console.error('draftsDb: getAll', error)
    }
  },
  async getByType (type: number): Promise<FullContract[]> {
    try {
      return (await dbPromise).getAllFromIndex('drafts', 'by-type', type)
    } catch (error) {
      console.error('draftsDb: getByType', error)
    }
  },
  async getByProperty (propertyId: string): Promise<FullContract[]> {
    try {
      return (await dbPromise).getAllFromIndex('drafts', 'by-property', propertyId)
    } catch (error) {
      console.error('draftsDb: getByProperty', error)
    }
  },
  async setById (contract: FullContract): Promise<IDBValidKey> {
    try {
      return (await dbPromise).put('drafts', cloneDeep(contract))
    } catch (error) {
      console.error('draftsDb: setById', error)
    }
  },
  async delete (id: FullContract['Id']): Promise<void> {
    try {
      return (await dbPromise).delete('drafts', id)
    } catch (error) {
      console.error('draftsDb: delete', error)
    }
  },
  async clear (): Promise<void> {
    try {
      return (await dbPromise).clear('drafts')
    } catch (error) {
      console.error('draftsDb: clear', error)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDraftsDb = () => ({
  draftsDb,
  insertDrafts
})
